<template>
  <div class="container-wrapper-input" :class="[theme]">
      <p class="title">{{title}}</p>
    <div
    class="input-control"
    :class="{completed: completed, [theme]: true}"
    >
      <p class="error">{{error}}</p>
      <slot>
        <input
          v-bind="$attrs"
          :class="[theme]"
          :placeholder="placeholder"
          v-model="phone"
          v-on="inputListeners"
        >
      </slot>
      <p class="warn">{{alert}}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'input-form',
  inheritAttrs: false,
  props: {
    themeStyle: {
      type: Object
    },
    status: [String, InputEvent],
    alert: String,
    error: String,
    placeholder: String,
    text: [String, Array],
    title: String,
    phoneNumber: [String, InputEvent],
    theme: {
      type: String,
      default: 'primary'
    }
  },
  data () {
    return {
      phone: this.phoneNumber,
      completed: false
    }
  },
  mounted () {
    this.completed = !!this.phone
  },
  updated () {
    if (!this.phoneNumber) {
      this.phone = ''
      this.completed = false
    }
  },
  computed: {
    inputListeners: function () {
      var vm = this
      return Object.assign({},
        this.$listeners,
        {
          blur: function (event) {
            if (event.target.value) {
              vm.completed = true
            } else {
              vm.completed = false
            }
          },
          focus: function () {
            // vm.completed = false
          }
        }
      )
    }
  },
  watch: {
    'phone': function (w) {
      this.phone = w.replace(/[a-zA-Z]/gi, '')
      this.$emit('input', this.phone)
    }
  }
}
</script>

<style lang="scss" scoped>
.primary {
    color: #6a4c7cc0;
  }
.empresa {
  color:  #78C4C7;
  }
.container-wrapper-input  {
  text-align: left;
  .title {
    margin-bottom: 0px;
    color: inherit;
    font-size: 18px;
    text-align: left;
    font-family: "Roboto", sans-serif !important;
    left: 0;
  }
  input {
    color: inherit;
    &::placeholder {
      color: rgba(121, 113, 113, 0.377);
    }
  }
  .error {
    position: absolute;
    left: 5px;
    top: 0;
    font-size: 12px;
    color: #f00;
  }
  .warn {
    position: absolute;
    left: 5px;
    bottom: 0;
    font-size: 12px;
    color: #ccc;
  }
}
.shadow {
  .input-control {
    border-style: none;
    padding: .5rem;
    box-shadow: 0 2px 12px -2px rgba(68, 68, 68, 0.226);
  }
}
.font-small {
  .input-control {
    height: 50px;
    padding: 0.5em;
  }
  .title {
    font-weight: none;
    font-size: 14px;
  }
  .input-control {
    input, label, input::placeholder {
      font-size: 14px;
    }
  }
}

.input-control {
  position: relative;
  background-color: #fff;
  border: 2px solid;
  width: 100%;
  height: 60px;
  border-radius: 6px;
  padding: 1rem;
  display: flex;
  position: relative;
  input {
    width: 100%;
    flex: 1;
    border-style: none;
    outline-style: none;
    font-size: 16px;
    background: transparent;
    text-align: left;
    /* color: #4B335A; */
  }
  input {
    font-weight: 100;
    color: rgb(124, 124, 124);
    font-family: 'NexaLight';
    @media(max-width:560px){
      font-size: 16px;
    }
  }
  &.completed {
    background-color: #50366054;
    input {
      color: #fff;
    }
  }
  input[disabled] {
    background-color: transparent;
  }
}
.input {
  display: flex;
  align-items: center;
  input {
    display: none;
  }
}
input:checked + .btn-radio .item {
  display: block;
}
input {
  width: 100%;
  flex: 1;
  border-style: none;
  outline-style: none;
  font-size: 1.3rem;
  background: transparent;
  text-align: left;
;
}
input[disabled] {
  background-color: transparent;
}
input::placeholder, input, label {
  font-weight: 100;
  color: inherit;
  font-family: 'NexaLight';
  @media(max-width:560px){
    font-size: 16px!important;
  }
}
input {
  color: inherit;
}

</style>
